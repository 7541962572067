/*
|--------------------------------------------------------------------------
| Legacy class from eProjectierung 1.0
|--------------------------------------------------------------------------
*/
import fn from './GlobalService'
import _isObject from 'lodash/isObject'
import loads from '../config/loads.json'

const CalculationService = class {
  constructor () {
    // Calculation method [ cabin | shaft ]
    this.calc = null

    // Load values
    // id: load range Q1
    // range: range of weight for this load
    // range.d1: elevators with one door
    // range.d2: elevators with two doors
    // car: minimum and maximum car dimensions
    // wa1Min: min. distance to wall left
    // wa1Max: max. distance to wall left
    // wa2Min: min. distance to wall right
    // wa2Max: max. distance to wall right
    // wa3Min: min. distance to wall back
    // sgMin: shaft pit
    // skMin: minimum shaft head at a car height of 2100
    this.loads = loads

    // Input values
    this.inputs = null

    // door and main settings for calculation
    this.settings = null

    // result values
    this.result = {}

    // errors, for debugging
    this.errno = 0
    this.lastErrno = 0

    // Loops steps for cabin optimization
    this.steps = {}

    // Cache for car boundaries
    this._cache = []

    // Debugging = logging to console
    this.debugDoor = '' // m2t | m2z | m4tz (avoid multiple out for each door)
  }

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   * Main procedure to start calculation
   * @param string calculation method
   * @param object input values
   * @param object setting values from door and main
   * @param bool slider, no adjustements are returned
   * @param object shaft, in case of user-selected adjustment
   * @return Object
   */
  do (calc, inputs, settings, slider, shaft) {
    this.debug = (settings.id === this.debugDoor)
    var Return, errmsg, i

    // Init
    this.calc = calc
    this.inputs = inputs
    this.settings = settings
    if (!shaft) {
      shaft = { kb: 0, kt: 0 }
    }
    this.initResult()
    this.initSteps()
    this.initCache()
    this.initError()
    Return = {
      errno: 0,
      result: this.result,
      adjustments: [],
      multi: [],
      tipps: [],
      errmsg: []
    }

    // Validate Input
    errmsg = this.validateUserInput()
    if (this.errno > 0) {
      Return.errno = this.errno
      Return.errmsg = errmsg
      return Return
    }

    // Calculate from Cabin
    if (this.calc === 'cabin') {
      errmsg = this.calcFromCabin(slider)

      // Result OK
      if (this.errno === 0) {
        Return.result = this.result
        if (slider === false) {
          Return.tipps = this.getTipps()
        }

      // Errors
      } else {
        Return.errno = this.errno
        if (slider === false) {
          if (errmsg) {
            switch (this.errno) {
              case 19:
                Return.adjustments = errmsg
                break
              case 20:
              case 23:
              case 25:
              case 28:
              case 29:
                Return.errmsg = errmsg
                break
              default:
                Return.errno = 1
            }
          } else {
            Return.errno = 1
          }
        }
      }
    }

    // Calculate from Shaft
    if (this.calc === 'shaft') {
      if (!_isObject(shaft)) {
        shaft = { kb: null, kt: null }
      }
      errmsg = this.calcFromShaft(shaft.kb, shaft.kt, slider)

      // Result OK
      if (this.errno === 0) {
        Return.result = this.result
        if (slider === false) {
          Return.tipps = this.getTipps()
        }

        // Errors
      } else {
        Return.errno = this.errno
        if (slider === false) {
          if (errmsg) {
            switch (this.errno) {
              case 16:
                Return.multi = errmsg
                break
              case 18:
                Return.adjustments = errmsg
                break
              case 22:
              case 25:
              case 28:
              case 29:
                Return.errmsg = errmsg
                break
              default:
                Return.errno = 1
            }
          } else {
            Return.errno = 1
          }
        }
      }
    }
    return Return
  }

  /*
  |--------------------------------------------------------------------------
  | Init
  |--------------------------------------------------------------------------
  */

  /**
   */
  initResult () {

    // same definition in door.js
    this.result = {
      kb: (this.calc === 'cabin') ? this.inputs.kb.value : 0,
      kt: (this.calc === 'cabin') ? this.inputs.kt.value : 0,
      sb: (this.calc === 'shaft') ? this.inputs.sb.value : 0,
      st: (this.calc === 'shaft') ? this.inputs.st.value : 0,
      tb: this.inputs.tb.value,
      kh: this.inputs.kh.value,
      tn: this.inputs.tn.value,
      dl: this.inputs.dl.value,
      ge: this.inputs.ge.value,
      fh: this.inputs.fh.value,
      skRed: this.inputs.skRed.value,
      sgRed: this.inputs.sgRed.value,
      nb: null,
      nl: 0,
      pe: 0,
      fl: 0,
      wa1Min: 0,
      wa1Door: 0,
      wa1: 0,
      wa2Min: 0,
      wa2Door: 0,
      wa2: 0,
      wa3: 0,
      sg: 0,
      sk: 0
    }
  }

  initSteps () {
    this.steps.kb = this.getLoopSteps(this.inputs.kb.step)
    this.steps.kt = this.getLoopSteps(this.inputs.kt.step)
  }

  initCache () {
    this._cache = {
      get_car_boundaries: {},
      sub_calc_car_optimize: {}
    }
  }

  initError () {
    this.errno = 0
  }

  setError (errno) {
    this.errno = errno
  }

  /*
  |--------------------------------------------------------------------------
  | Calculation
  |--------------------------------------------------------------------------
  */

  /**
   * Get shaft dimensions from given car
   * @param bool slider
   * @return array adjustements or messages
   */
  calcFromCabin (slider) {

    var Load, Res, flMax, rangeMax, kbNew, ktNew, param1,
      lastLoad, tbMax, _car, _floor, _adj, _a, i, k, l, errmsg

    // get valid cars, function either returns same values (if they are valid) or one or more adjustments
    // Load may be null if it's too big for q5, getCarBoundaries() can handle that
    Load = this.subCalcLoadFromCar(
      this.result.kb,
      this.result.kt,
      this.result.tb,
      this.result.dl,
      this.result.ge
    )
    if (slider) {

      // Unlike in subCalcCarFromShaft, values for kb and kt are already a multiple of step. If not, this is
      // a problem of the frontend. We can not calculate with other values than the given. So no
      // fn.sanitize is done here!
      _car = this.getCarBoundaries(
        Load,
        this.result.kb,
        this.result.kt,
        this.result.tb,
        this.result.dl,
        this.result.ge,
        true,
        false,
        false,
        true
      )
      if (_car.length === 0) {
        this.setError(19)
        return
      }
    } else {
      _car = this.getCarBoundaries(
        Load,
        this.result.kb,
        this.result.kt,
        this.result.tb,
        this.result.dl,
        this.result.ge,
        true,
        true,
        true,
        true
      )
     
      // if cabin is too big for q5, we must not return an error to calculate adjustments at the end or this function
      // if (_car.length === 0) {
      //  setError(1);
      //  return;
      // }
    }

    // one car with unchanged values -> result
    if (_car.length === 1 && _car[0][0] === this.result.kb && _car[0][1] === this.result.kt) {
      Res = this.subCalcShaftFromCar(
        this.result.kb,
        this.result.kt,
        this.result.kh,
        this.result.tb,
        this.result.dl,
        this.result.ge,
        this.result.fh,
        this.result.tn,
        this.result.skRed,
        this.result.sgRed
      )

      if (Res.error === 0) {

        // check for invalid speed
        errmsg = this.validateInputGe(this.result.ge, Load.ge)
        if (this.errno > 0) {
          return errmsg
        }

        // check for invalid shaftpid/head reduce
        errmsg = this.validateInputSkRed(Res.data.nb, this.result.skRed)
        if (this.errno > 0) {
          return errmsg
        }
        errmsg = this.validateInputSgRed(Res.data.nb, this.result.sgRed)
        if (this.errno > 0) {
          return errmsg
        }

        // passed everything -> result
        this.setResult(Res.data)
        return
      }      
      
      // max wall distances are reached as a result of door width.
      // Calculate the next smaller door that will give valid result.
      else if (Res.error === 20) {
        // calculate next smaller door that result in valid wall distances
        tbMax = this.result.tb
        while (tbMax > this.settings.min) {
          tbMax--

          // step needed, because it is set only to current door
          tbMax = fn.sanitize(tbMax, this.settings.min, this.settings.max, this.settings.step, 'down')
          Res = this.subCalcShaftFromCar(
            this.result.kb,
            this.result.kt,
            this.result.kh,
            tbMax,
            this.result.dl,
            this.result.ge,
            this.result.fh,
            this.result.tn,
            this.result.skRed,
            this.result.sgRed
          )
          if (Res.error === 0) {
            this.setError(20)
            return ['str20', tbMax, null, { tb: tbMax }]
          }
        }

        // no valid door width found, fatal error
        this.setError(1)
        return
      }
    }

    if (slider) return

    // get possible adjustments
    _adj = []
    _a = []
    _a = this.subCalcAddCarAdjustment(
      Load,
      _a,
      this.result.kb,
      this.result.kt,
      this.result.kb,
      this.result.kt,
      this.result.tb,
      this.result.dl,
      this.result.ge,
      true,
      true,
      false
    )
    for (k in _a) {
      Res = this.subCalcShaftFromCar(
        _a[k][0],
        _a[k][1],
        this.result.kh,
        this.result.tb,
        this.result.dl,
        this.result.ge,
        this.result.fh,
        this.result.tn,
        this.result.skRed,
        this.result.sgRed
      )
      if (Res.error === 0) {
        _adj.push(_a[k])
      }
    }
    if (_adj.length > 0) {
      this.setError(19)
      return _adj
    }

    // Car may be too big due to max load of q5, so all the adjustments above have not been valid
    // same in calc2() and subCalcCarFromShaft()
    lastLoad = this.loads.length - 1 // simply last = highest load
    _floor = this.subCalcFloorValues(this.result.kb, this.result.kt, this.result.tb)
    rangeMax = this.getPrefRangeMax(this.loads[lastLoad], this.result.ge)
    if (_floor.nl > rangeMax) {
      flMax = this.subCalcSpaceFromLoad(rangeMax)

      // Keep kt, adjust kb
      // return_unchanged === true, because the values kb/kt are already reduced and it may be, that they already fit.
      kbNew = Math.floor(flMax * 1000000 / this.result.kt)
      if (this.inputs.kb.step > 1) {
        kbNew = fn.sanitize(kbNew, this.inputs.kb.min, this.inputs.kb.max, this.inputs.kb.step, 'down')
      }
      _adj = this.subCalcAddCarAdjustment(
        this.loads[lastLoad],
        _adj,
        this.result.kb,
        this.result.kt,
        kbNew,
        this.result.kt,
        this.result.tb,
        this.result.dl,
        this.result.ge,
        false,
        true,
        true
      )

      // Keep kbMax, adjust kt
      // return_unchanged === true, because the values kb/kt are already reduced and it may be, that they already fit.
      ktNew = Math.floor(flMax * 1000000 / this.result.kb)
      if (this.inputs.kt.step > 1) {
        ktNew = fn.sanitize(ktNew, this.inputs.kt.min, this.inputs.kt.max, this.inputs.kt.step, 'down')
      }
      _adj = this.subCalcAddCarAdjustment(
        this.loads[lastLoad],
        _adj,
        this.result.kb,
        this.result.kt,
        this.result.kb,
        ktNew,
        this.result.tb,
        this.result.dl,
        this.result.ge,
        false,
        true,
        true
      )
      if (_adj.length > 0) {
        this.setError(19)
        return _adj
      }

      // no result - this may be because kb AND kt are too big for hight speed
      // Show a message to the user to reduce speed
      if (this.result.ge === 'v2') {
        for (l = 0; l < this.inputs.ge.options.length; l++) {
          if (this.inputs.ge.options[l].value === 'v1') {
            param1 = this.inputs.ge.options[l].label
          }
        }
        this.setError(23)
        return ['str23', param1, null, { ge: 'v1' }]
      }
    }

    // everything failed
    this.setError(1)
  }

  /**
   * Get car dimensions from given shaft
   */
  calcFromShaft (kbPref, ktPref, slider) {
    var Res, i, Load, errmsg
    
    Res = this.subCalcCarFromShaft(
      this.result.sb,
      this.result.st,
      this.result.tb,
      this.result.kh,
      this.result.dl,
      this.result.ge,
      this.result.fh,
      this.result.tn,
      this.result.skRed,
      this.result.sgRed,
      kbPref,
      ktPref,
      slider
    )

    if (Res.error === 0) {

      // check for invalid speed
      Load = this.getLoadById(Res.data.nb)
      errmsg = this.validateInputGe(this.result.ge, Load.ge)
      if (this.errno > 0) {
        return errmsg
      }

      // check for invalid shaftpid/head reduce
      errmsg = this.validateInputSkRed(Res.data.nb, this.result.skRed)
      if (this.errno > 0) {
        return errmsg
      }
      errmsg = this.validateInputSgRed(Res.data.nb, this.result.sgRed)
      if (this.errno > 0) {
        return errmsg
      }

      // passed everything -> result
      this.setResult(Res.data)
      return
    }
    this.setError(Res.error)
    return Res.data
  }

  /**
   * store result of calculation if no error occured
   */
  setResult (data) {
    var i
    for (i in data) {
      this.result[i] = data[i]
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Sub-calculation Main Procedures
  |--------------------------------------------------------------------------
  */

  /**
   * Main procedure for calc1
   */
  subCalcShaftFromCar (
    kbParam,
    ktParam,
    khParam,
    tbParam,
    dlParam,
    geParam,
    fhParam,
    tnParam,
    skRedParam,
    sgRedParam
  ) {
    var rangeMin, rangeMax, wa1Max, wa2Max, _a, _floor, i

    _a = []
    _floor = this.subCalcFloorValues(kbParam, ktParam, tbParam)
    _a.fl = _floor.fl
    _a.nl = _floor.nl
    _a.pe = _floor.pe

    // Load range
    for (i in this.loads) {
      rangeMin = this.getPrefRangeMin(this.loads[i])
      rangeMax = this.getPrefRangeMax(this.loads[i], geParam)
      if (_a.nl >= rangeMin && _a.nl <= rangeMax) {
        _a.nb = this.getPrefId(this.loads[i])
        this.log('load', 'nb ' + _a.nb)

        // Wall distances
        _a.wa1Min = this.subCalcWa1Min(this.loads[i], fhParam, geParam, _a.nl)
        _a.wa1Door = this.subCalcWa1FromDoor(this.loads[i], kbParam, tbParam, dlParam, _a.nl)
        _a.wa2Min = this.subCalcWa2Min(this.loads[i], fhParam, geParam, _a.nl)
        _a.wa2Door = this.subCalcWa2FromDoor(this.loads[i], kbParam, tbParam, dlParam, _a.nl)

        // use bigger values
        if (_a.wa1Min > _a.wa1Door) {
          this.log('use wa1Min ' + _a.wa1Min)
          _a.wa1 = _a.wa1Min
        } else {
          this.log('use wa1Door ' + _a.wa1Door)
          _a.wa1 = _a.wa1Door
        }
        if (_a.wa2Min > _a.wa2Door) {
          this.log('use wa2Min ' + _a.wa2Min)
          _a.wa2 = _a.wa2Min
        } else {
          this.log('use wa2Door ' + _a.wa2Door)
          _a.wa2 = _a.wa2Door
        }

        // check, if wa_max are reached and return error
        wa1Max = this.getPrefWa1Max(this.loads[i], geParam)
        wa2Max = this.getPrefWa2Max(this.loads[i], geParam)
        if (_a.wa1 > wa1Max || _a.wa2 > wa2Max) {

          // It's not possible to calculate the smaller door-width here by simply substracting the difference
          // between wa and wa_max. subCalcWa1FromDoor() etc. have factors in their calculations that result
          // in a non-linear dependence of wall distances and door width
          return {
            error: 20 // this.setError(20) just to be able to find this
          }
        }

        // Shaft
        _a.sb = this.subCalcShaftWidth(kbParam, _a.wa1, _a.wa2)
        _a.wa3 = this.subCalcWa3MinFromCar(this.loads[i], ktParam)
        _a.st = this.subCalcShaftDepthFromCar(ktParam, _a.wa3, tnParam, dlParam)
        _a.sk = this.subCalcShaftHead(this.loads[i], khParam, geParam, fhParam, skRedParam)
        _a.sg = this.subCalcShaftPit(this.loads[i], kbParam, ktParam, geParam, fhParam, sgRedParam)

        return {
          error: 0,
          data: _a
        }
      }
    }
    return {
      error: 1, // this.setError(1) just to be able to find this
      data: null
    }
  }

  // ###

  /**
   * calc1: given original kb and kt, possibles adjustments are calculated
   */
  subCalcAddCarAdjustment (
    Load,
    _car,
    kbOrig,
    ktOrig,
    kb,
    kt,
    tb,
    dl,
    ge,
    getBigger,
    getSmaller,
    getUnchanged
  ) {
    var _carNew, kbAdj, ktAdj, i

    // so far no kb and kt sanitize required here, because this function is only invoked
    // by calcFromCabin and values always should be a multiple of step
    _carNew = this.getCarBoundaries(Load, kb, kt, tb, dl, ge, true, getBigger, getSmaller, getUnchanged)
    if (_carNew.length > 0) {
      for (i in _carNew) {
        kbAdj = _carNew[i][0] !== kbOrig
        ktAdj = _carNew[i][1] !== ktOrig
        if (kbAdj && ktAdj) continue // at least one value should be like original
        if (kbAdj || ktAdj) {
          _car.push([_carNew[i][0], _carNew[i][1], kbAdj, ktAdj])
        }
      }
    }
    return _car
  }

  /**
   * given original sb and st, possibles adjustments are calculated
   */
  subCalcAddShaftAdjustment (
    _shaft,
    sbOrig,
    stOrig,
    kb,
    kt,
    kh,
    tb,
    dl,
    ge,
    fh,
    tn,
    skRed,
    sgRed
  ) {
    var sbNew, stNew, sbAdj, stAdj, Res
    Res = this.subCalcShaftFromCar(
      kb,
      kt,
      kh,
      tb,
      dl,
      ge,
      fh,
      tn,
      skRed,
      sgRed
    )
    if (Res.error === 0) {
      if (this.calc === 'cabin') {
        sbNew = Res.data.sb
        stNew = Res.data.st
      } else {
        sbNew = fn.sanitize(Res.data.sb, this.inputs.sb.min, this.inputs.sb.max, this.inputs.sb.step, 'down')
        stNew = fn.sanitize(Res.data.st, this.inputs.st.min, this.inputs.st.max, this.inputs.st.step, 'down')
      }
      sbAdj = sbNew !== sbOrig
      stAdj = stNew !== stOrig
      if (sbAdj || stAdj) {
        _shaft.push([sbNew, stNew, sbAdj, stAdj])
      }
    }
    return _shaft
  }

  /**
   * Main procedure for calc2: Get car dimensions from given shaft
   */
  subCalcCarFromShaft (
    sbParam,
    stParam,
    tbParam,
    khParam,
    dlParam,
    geParam,
    fhParam,
    tnParam,
    skRedParam,
    sgRedParam,
    kbPref,
    ktPref,
    slider
  ) {
    var _car, _cars, _shaft, _carMax, _load, _floor, _optKt, _optKb,
      _b, _res, _multi, flMax, ktNew, kbNew, nlMax,
      sbMin, tbMax, tbMaxAll, breakLoop, inLoadRange, inPrevLoadRange, ktTooSmall,
      wa12TooBig, loadTooBig, carTooSmall, isFirstLoadRange, kb, kt, i, j, k, l, m, n

    // Unlinke in calc1, we don't have 2 calculation ways to get max car width.
    // This is, because the calculation two is already completed when the user
    // sets shaft or door with. We simply have to get the tallest car that fits into the
    // shaft by calculation one.

    // But we have the problem, that we need load to get wall distances (wa1 - wa3),
    // but we can't calculate load without having car dimensions. So we need to
    // check every load q1-q5
    _cars = [] // temp result
    _shaft = [] // possible bigger shaft dimensions if the calculated cabin is too small
    _carMax = [0, 0] // kb, kt
    breakLoop = this.loads.length

    // Put global values per Load in an array, so it is easier to reselect previous values if needed
    _load = []

    // We have to loop  ALL loads, because it is possible, that we get valid cars in q3 and q5 (for instance).
    // But we don't have to loop more than 2 loads further after the last valid car. That's done by checking
    // breakLoop at the end of each loop.
    for (i in this.loads) {
      _load[i] = []
      _load[i].nb = this.getPrefId(this.loads[i])
      _load[i].rangeMin = this.getPrefRangeMin(this.loads[i])
      _load[i].rangeMax = this.getPrefRangeMax(this.loads[i], geParam)
      _load[i].wa1Min = this.getPrefWa1Min(this.loads[i], fhParam, geParam)
      _load[i].wa2Min = this.getPrefWa2Min(this.loads[i], fhParam, geParam)
      _load[i].wa3Min = this.subCalcWa3MinFromShaft(this.loads[i])
      _load[i].ktMax = this.subCalcCarDepthMaxFromShaft(stParam, _load[i].wa3Min, tnParam, dlParam)
      _load[i].ktMin = this.getPrefKtMin(this.loads[i], geParam)
      _load[i].kbMax = this.subCalcCarWithMaxFromShaftDoor(_load[i], sbParam, tbParam, dlParam)

      // #NEW make kb and kt a multiple of step
      // write values back to _load[i], because they are used multiple times below
      if (this.inputs.kb.step > 1) {
        _load[i].kbMax = fn.sanitize(_load[i].kbMax, this.inputs.kb.min, this.inputs.kb.max, this.inputs.kb.step, 'down')
      }
      if (this.inputs.kt.step > 1) {
        _load[i].ktMax = fn.sanitize(_load[i].ktMax, this.inputs.kt.min, this.inputs.kt.max, this.inputs.kt.step, 'down')
      }

      // valid cabin dimensions, _car may contain 0 - 2 combinations of kb/kt
      // get bigger cars here too, because if shaft is too small we need the bigger car to be calculated and run into error 18
      _car = this.getCarBoundaries(this.loads[i], _load[i].kbMax, _load[i].ktMax, tbParam, dlParam, geParam, false, true, true, true)

      // no result -> continue with next load
      if (_car.length === 0) {
        continue
      }

      // continue calculation with all results to check if they remain valid
      for (j in _car) {
        _b = []
        _b.wa1 = _load[i].wa1Min
        _b.wa2 = _load[i].wa2Min
        _b.wa3 = _load[i].wa3Min
        _b.kb = _car[j][0]
        _b.kt = _car[j][1]
        _floor = this.subCalcFloorValues(_b.kb, _b.kt, tbParam)
        _b.fl = _floor.fl
        _b.nl = _floor.nl
        _b.nb = _load[i].nb

        // calc max. kb and kt that fit in this shaft for this load-range
        inLoadRange = (_b.nl >= _load[i].rangeMin && _b.nl <= _load[i].rangeMax)
        inPrevLoadRange = (_b.nl < _load[i].rangeMin)
        ktTooSmall = (_load[i].ktMax < _load[i].ktMin)
        wa12TooBig = (this.subCalcCheckWaMax(this.loads[i], sbParam, _b.kb, geParam) === false)
        loadTooBig = (_b.nl > _load[i].rangeMax && _b.nb === 'q5')
        carTooSmall = (_car[j][0] > _load[i].kbMax || _car[j][1] > _load[i].ktMax)
        isFirstLoadRange = (_b.nb === 'q1')

        // Results that have too SMALL dimensions due to wa_max
        if (inLoadRange && wa12TooBig) {
          _shaft = this.subCalcAddShaftAdjustment(
            _shaft,
            sbParam,
            stParam,
            _car[j][0],
            _car[j][1],
            khParam,
            tbParam,
            dlParam,
            geParam,
            fhParam,
            tnParam,
            skRedParam,
            sgRedParam
          )
          continue
        }

        // Results that have BIGGER car dimensions in q1 (shaft is too small for any cabin)
        if (isFirstLoadRange && _b.nl <= _load[i].rangeMax && carTooSmall) {
          _shaft = this.subCalcAddShaftAdjustment(
            _shaft,
            sbParam,
            stParam,
            _car[j][0],
            _car[j][1],
            khParam,
            tbParam,
            dlParam,
            geParam,
            fhParam,
            tnParam,
            skRedParam,
            sgRedParam
          )
          continue
        }

        // Optimization
        // re-select previous load range and eventually show multiple results
        if (ktTooSmall || inPrevLoadRange || loadTooBig) {
          // re-select not possible in first load range
          if (isFirstLoadRange) {
            _shaft = this.subCalcAddShaftAdjustment(
              _shaft,
              sbParam,
              stParam,
              _load[i].kbMax,
              _load[i].ktMin,
              khParam,
              tbParam,
              dlParam,
              geParam,
              fhParam,
              tnParam,
              skRedParam,
              sgRedParam
            )
            breakLoop = fn.parseInt(i)
            break
          }

          // reselect values from previous load range
          if (loadTooBig) {
            k = i
          } else {
            k = i - 1
          }
          _b.wa1 = _load[k].wa1Min
          _b.wa2 = _load[k].wa2Min
          _b.wa3 = _load[k].wa3Min
          _b.nb = _load[k].nb
          nlMax = this.getPrefRangeMax(this.loads[k], geParam)

          // optimize car by making kt smaller (starting with max-values)
          _optKt = this.subCalcCarOptimize(
            this.loads[k],
            _load[k].kbMax,
            _load[k].ktMax,
            nlMax,
            tbParam,
            dlParam,
            geParam,
            'kt'
          )
          if (_optKt !== null) {
            _optKt.wa1 = _b.wa1
            _optKt.wa2 = _b.wa2
            _optKt.wa3 = _b.wa3
            _optKt.nb = _b.nb
            _optKt.pe = this.subCalcPersons(_optKt.nl)
            _optKt.sk = this.subCalcShaftHead(this.loads[k], khParam, geParam, fhParam, skRedParam)
            _optKt.sg = this.subCalcShaftPit(this.loads[k], _optKt.kb, _optKt.kt, geParam, fhParam, sgRedParam)
            if (_optKt.kb > _carMax[0] || _optKt.kt > _carMax[1]) {
              // check wa_max
              if (this.subCalcCheckWaMax(this.loads[k], sbParam, _optKt.kb, geParam) === false) {
                continue
              }
              if (_optKt.kb > _carMax[0]) _carMax[0] = _optKt.kb
              if (_optKt.kt > _carMax[1]) _carMax[1] = _optKt.kt

              // add to result
              _cars.push(_optKt)
              if (slider && _cars.length === 1) {
                breakLoop = fn.parseInt(i)
                break
              } else {
                breakLoop = fn.parseInt(i) + 2
              }
            }
          }

          // optimize car by making kb smaller (starting with max-values)
          // don't execute if ktTooSmall
          if (inPrevLoadRange || loadTooBig) {
            _optKb = this.subCalcCarOptimize(
              this.loads[k],
              _load[k].kbMax,
              _load[k].ktMax,
              nlMax,
              tbParam,
              dlParam,
              geParam,
              'kb'
            )
            if (_optKb !== null) {
              _optKb.wa1 = _b.wa1
              _optKb.wa2 = _b.wa2
              _optKb.wa3 = _b.wa3
              _optKb.nb = _b.nb
              _optKb.pe = this.subCalcPersons(_optKb.nl)
              _optKb.sk = this.subCalcShaftHead(this.loads[k], khParam, geParam, fhParam, skRedParam)
              _optKb.sg = this.subCalcShaftPit(this.loads[k], _optKb.kb, _optKb.kt, geParam, fhParam, sgRedParam)
              if (_optKb.kb > _carMax[0] || _optKb.kt > _carMax[1]) {
                // check wa_max
                if (this.subCalcCheckWaMax(this.loads[k], sbParam, _optKb.kb, geParam) === false) {
                  continue
                }
                if (_optKb.kb > _carMax[0]) _carMax[0] = _optKb.kb
                if (_optKb.kt > _carMax[1]) _carMax[1] = _optKb.kt

                // add to result
                _cars.push(_optKb)
                if (slider && _cars.length === 1) {
                  breakLoop = fn.parseInt(i)
                  break
                } else {
                  breakLoop = fn.parseInt(i) + 2
                }
              }
            }
          }
          continue
        }

        // Normal case: car is in load range
        if (inLoadRange) {
          _b.pe = this.subCalcPersons(_b.nl)
          _b.sk = this.subCalcShaftHead(this.loads[i], khParam, geParam, fhParam, skRedParam)
          _b.sg = this.subCalcShaftPit(this.loads[i], _b.kb, _b.kt, geParam, fhParam, sgRedParam)
          if (_b.kb > _carMax[0] || _b.kt > _carMax[1]) {
            if (_b.kb > _carMax[0]) _carMax[0] = _b.kb
            if (_b.kt > _carMax[1]) _carMax[1] = _b.kt

            // add to result
            _cars.push(_b)
            if (slider && _cars.length === 1) {
              breakLoop = fn.parseInt(i)
              break
            } else {
              breakLoop = fn.parseInt(i) + 2
            }
          }
          continue
        }
      }

      // Car is too big and therefore outside q5, no optimizations are found
      // _car already contains a valid car dimensions, but it is not sure, that this dimension is in
      // load range. In fact it may happen that load is bigger. So we have to get the next smaller valid car.
      if (_cars.length === 0 && _load[i].nb === 'q5') {
        // calc maximum car space for rangeMax
        flMax = this.subCalcSpaceFromLoad(_load[i].rangeMax)

        // Keep ktMax, adjust kb
        kbNew = Math.floor(flMax * 1000000 / _load[i].ktMax)
        _shaft = this.subCalcAddShaftAdjustment(
          _shaft,
          sbParam,
          stParam,
          kbNew,
          _load[i].ktMax,
          khParam,
          tbParam,
          dlParam,
          geParam,
          fhParam,
          tnParam,
          skRedParam,
          sgRedParam
        )

        // Keep kbMax, adjust kt
        ktNew = Math.floor(flMax * 1000000 / _load[i].kbMax)
        _shaft = this.subCalcAddShaftAdjustment(
          _shaft,
          sbParam,
          stParam,
          _load[i].kbMax,
          ktNew,
          khParam,
          tbParam,
          dlParam,
          geParam,
          fhParam,
          tnParam,
          skRedParam,
          sgRedParam
        )
      }

      // abort, see comment at begin of loop
      if (i === breakLoop) {
        break
      }
    }

    // no result
    if (_cars.length === 0) {
      // Show shaft adjustment
      if (_shaft.length > 0) {
        return {
          error: 18, // this.setError(18) just to be able to find this
          data: _shaft
        }
      }
    }

    // loop results and check if door fits in shaft
    _res = []
    tbMaxAll = 0
    for (l in _cars) {
      tbMax = this.subCalcDoorWidthMaxFromCarWidth(_cars[l].kb)
      tbMax = fn.sanitize(tbMax, this.settings.min, this.settings.max, this.settings.step, 'down')
      sbMin = this.subCalcShaftWidthMinFromDoorWidth(tbMax, dlParam)

      // tbMax is smaller than tbParam, because calculated shaft is too small
      if (tbMax < tbParam) {
        // tbMax results in a shaft that is smaller than parameter > skip result
        if (sbMin > sbParam) {
          continue
        }

        if (tbMax > tbMaxAll) {
          tbMaxAll = tbMax
        }
        continue
      }

      // valid result
      _res.push(_cars[l])
    }

    // no result, because all cars need a smaller door, show message with door adjustment
    if (_res.length === 0 && tbMaxAll > 0) {
      return {
        error: 22, // this.setError(22) just to be able to find this
        data: ['str22', tbMaxAll, null, { tb: tbMaxAll }]
      }
    }

    // one result -> use
    if (_res.length === 1) {
      return {
        error: 0,
        data: _cars[0]
      }
    }

    // more than one result, show multiple results
    if (_res.length > 1) {
      // If preffered dimensions are given (after user clicked on button in error-car-page), we only
      // take the combination that is equal to these values
      if (kbPref === undefined || kbPref === null) kbPref = 0
      if (ktPref === undefined || ktPref === null) ktPref = 0
      if (kbPref > 0 || ktPref > 0) {
        for (m in _res) {
          if (
            (_res[m].kb === kbPref || kbPref === 0) &&
            (_res[m].kt === ktPref || ktPref === 0)
          ) {
            return {
              error: 0,
              data: _res[m]
            }
          }
        }
      }

      // return multiple results
      _multi = []
      for (n in _res) {
        _multi.push([_res[n].kb, _res[n].kt, false, false])
      }
      return {
        error: 16, // this.setError(16) just to be able to find this
        data: _multi
      }
    }

    // load-loop ended with no result
    return {
      error: 1, // this.setError(1) just to be able to find this
      data: null
    }
  }

  /**
   * make a given car smaller until it fits into given nl
   */
  subCalcCarOptimize (Load, kbMax, ktMax, nlMax, tbParam, dlParam, geParam, mode) {
    var val1, val2, fl, nl, min, step, _floor, cacheid, steps, _a, _b

    // cache
    cacheid = Load.id + '-' + kbMax + '-' + ktMax + '-' + nlMax + '-' + tbParam + '-' + dlParam + '-' + geParam + '-' + mode
    if (this._cache.sub_calc_car_optimize[cacheid] !== undefined) {
      return this._cache.sub_calc_car_optimize[cacheid]
    }

    // reduce longer side in steps of 1, use factor to calculate other side in the same ratio
    switch (mode) {
      case 'kb':
        val1 = kbMax
        val2 = ktMax
        min = this.getPrefKbMin(Load)
        steps = this.steps.kb
        break
      case 'kt':
        val1 = ktMax
        val2 = kbMax
        min = this.getPrefKtMin(Load, geParam)
        steps = this.steps.kt
        break
    }
    step = steps.start
    val1 -= steps.start
    for (; val1 >= min; val1 -= step) {
      if (mode === 'kb') {
        _floor = this.subCalcFloorValues(val1, val2, tbParam)
        fl = _floor.fl
        nl = _floor.nl
      } else {
        _floor = this.subCalcFloorValues(val2, val1, tbParam)
        fl = _floor.fl
        nl = _floor.nl
      }

      // car dimensions are small enough to fit in load range
      if (nl <= nlMax) {
        _a = []

        // Check valid of car
        if (mode === 'kb') {
          _b = this.getCarBoundaries(
            Load,
            val1,
            val2,
            tbParam,
            dlParam,
            geParam,
            false,
            false,
            false,
            true
          )
          if (_b.length === 0) {
            continue
          }
        } else {
          _b = this.getCarBoundaries(
            Load,
            val2,
            val1,
            tbParam,
            dlParam,
            geParam,
            false,
            false,
            false,
            true
          )
          if (_b.length === 0) {
            continue
          }
        }
        if (steps.step1 > 0 && step === steps.step1) {
          val1 += (steps.step1 + steps.step2)
          if (mode === 'kb' && val1 > kbMax) {
            val1 = kbMax + steps.step2
          }
          if (mode === 'kt' && val1 > ktMax) {
            val1 = ktMax + steps.step2
          }
          step = steps.step2
          continue
        } else if (steps.step2 > 0 && step === steps.step2) {
          val1 += (steps.step2 + steps.step3)
          if (mode === 'kb' && val1 > kbMax) {
            val1 = kbMax + steps.step3
          }
          if (mode === 'kt' && val1 > ktMax) {
            val1 = ktMax + steps.step3
          }
          step = steps.step3
          continue
        } else {
          if (mode === 'kb') {
            _a.kb = val1
            _a.kt = val2
          } else {
            _a.kb = val2
            _a.kt = val1
          }
        }
        _a.fl = fl
        _a.nl = nl
        this._cache.sub_calc_car_optimize[cacheid] = _a
        return _a
      }
    }
    this._cache.sub_calc_car_optimize[cacheid] = null
    return null
  }

  /**
   * helper for subCalcCarFromShaft
   */
  subCalcCheckWaMax (Load, sb, kb, ge) {
    var wa, waMax
    wa = sb - kb
    waMax = this.getPrefWa1Max(Load, ge) + this.getPrefWa2Max(Load, ge)
    return (wa <= waMax)
  }

  // ###
  /*
  |--------------------------------------------------------------------------
  | Sub-calculation Floor, Load, Persons
  |--------------------------------------------------------------------------
  */

  /**
   * returns load object from given car dimensions
   */
  subCalcLoadFromCar (kb, kt, tb, dl, ge) {
    var _floor, rangeMin, rangeMax, i
    _floor = this.subCalcFloorValues(kb, kt, tb)
    for (i in this.loads) {
      rangeMin = this.getPrefRangeMin(this.loads[i])
      rangeMax = this.getPrefRangeMax(this.loads[i], ge)
      if (_floor.nl >= rangeMin && _floor.nl <= rangeMax) {
        return this.loads[i]
      }
    }
    return null
  }

  /**
   * Master for the following functions
   */
  subCalcFloorValues (kb, kt, tb) {
    var _res = []
    _res.fl = this.subCalcFloorSpace(kb, kt)
    _res.nl = this.subCalcLoadFromSpace(_res.fl)
    _res.pe = this.subCalcPersons(_res.nl)
    return _res
  }

  /**
   */
  subCalcFloorSpace (kb, kt) {
    return fn.round(kb * kt / 1000000, 4)
  }

  /**
   * Calculate load nl
   * @return integer
   */
  subCalcLoadFromSpace (fl, round) {
    var nl, b
    if (fl < 3.4) {
      nl = Math.ceil((fl * 500) - 200)
    } else {
      nl = Math.ceil((fl - 1) * 625)
    }

    // round up to multiple of roundLoad
    if (this.settings.roundLoad > 0) {
      b = nl % this.settings.roundLoad
      if (b > 0) {
        nl = nl - b + this.settings.roundLoad
      }
    }
    return nl
  }

  /**
   */
  subCalcSpaceFromLoad (nl) {
    var fl
    fl = (nl + 200) / 500
    if (fl >= 3.4) {
      fl = (nl / 625) + 1
    }
    return fn.round(fl, 4)
  }

  /**
   */
  subCalcPersons (nl) {
    return Math.floor(nl / 75)
  }

  /*
  |--------------------------------------------------------------------------
  | Sub-calculation Car
  |--------------------------------------------------------------------------
  */

  /**
   * calc minimum car width from a given door width
   */
  subCalcCarWidthMin (tb) {
    var kb
    if (tb < this.settings.min) tb = this.settings.min
    kb = tb + this.settings.tbKbRed
    return kb
  }

  /**
   * reverse calculation of subCalcShaftWidthFromCar
   */
  subCalcCarWithMax (sb, wa1, wa2) {
    return sb - wa1 - wa2
  }

  /**
   * reverse calculation of subCalcShaftDepthFromCar
   * tn can be 1 (normal door niches) or 2 (deep door niches)
   * deep door niches are not displayed in interface so far, but in tips
   */
  subCalcCarDepthMaxFromShaft (st, wa, tn, dl) {
    var kt, red
    red = this.getPrefShaftDepthReduce(tn, dl)
    if (dl === 'd2') {
      kt = st - (this.settings.tts * 2) + red
    } else {
      kt = st - wa - this.settings.tts + red
    }
    return kt
  }

  /**
   */
  getCarBoundaries (
    Load,
    kb,
    kt,
    tb,
    dl,
    ge,
    sanitize,
    getBigger,
    getSmaller,
    getUnchanged
  ) {

    var _res, _floor, cacheid, rangeMin, rangeMax, kbSmaller, kbBigger, ktSmaller, ktBigger,
      kbMin, kbMax, kbNew, step, breakLoop, ktMin, ktMax, ktNew, i, j, debugLoopCount

    // get smaller + bigger values, that are valid in combination with the unchanged other value
    kbSmaller = null // car width smaller
    kbBigger = null // car width bigger
    ktSmaller = null // car depth smaller
    ktBigger = null // car depth bigger
    _res = []
    debugLoopCount = 0

    // values are valid, so simply return them
    if (
      getUnchanged &&
      Load !== null &&
      kb >= this.getPrefKbMin(Load) &&
      kb <= this.getPrefKbMax(Load) &&
      kt >= this.getPrefKtMin(Load) &&
      kt <= this.getPrefKtMax(Load)
    ) {
      _res.push([kb, kt, false, false])
      return _res
    }

    if (sanitize) {
      cacheid = kb + '-' + kt + '-' + tb + '-' + dl + '-' + ge + '-1'
    } else {
      cacheid = kb + '-' + kt + '-' + tb + '-' + dl + '-' + ge + '-0'
    }
    if (this._cache.get_car_boundaries[cacheid] === undefined) {
      this._cache.get_car_boundaries[cacheid] = []
    }

    // get smaller values from cache
    if (getSmaller && this._cache.get_car_boundaries[cacheid].kbSmaller !== undefined) {
      kbSmaller = this._cache.get_car_boundaries[cacheid].kbSmaller
      ktSmaller = this._cache.get_car_boundaries[cacheid].ktSmaller

    // get smaller values, loop loads from big to small
    } else if (getSmaller) {
      this._cache.get_car_boundaries[cacheid].kbSmaller = null
      this._cache.get_car_boundaries[cacheid].ktSmaller = null
      for (i = (this.loads.length - 1); i >= 0; i--) {
        rangeMin = this.getPrefRangeMin(this.loads[i])
        rangeMax = this.getPrefRangeMax(this.loads[i], ge)

        // kt is valid in this load range, try to fit kb
        if (kt >= this.getPrefKtMin(this.loads[i]) && kt <= this.getPrefKtMax(this.loads[i])) {
          kbMin = this.getPrefKbMin(this.loads[i])
          kbMax = this.getPrefKbMax(this.loads[i])
          if (sanitize || this.inputs.kb.step > 1) {
            kbMin = fn.sanitize(kbMin, this.inputs.kb.min, this.inputs.kb.max, this.inputs.kb.step, 'up')
            kbMax = fn.sanitize(kbMax, this.inputs.kb.min, this.inputs.kb.max, this.inputs.kb.step, 'down')
          }

          // No smaller value possible in this load range because _min value is already too big
          if (kb < kbMin) continue

          // Compute the biggest of all smaller values. So enter in calculation, if there is the chance of a bigger value
          if (kbSmaller === null || kbSmaller < kbMax) {
            kbNew = kbMax - this.steps.kb.step1
            step = this.steps.kb.start
            breakLoop = false
            for (; kbNew >= kbMin; kbNew -= step) {
              if (breakLoop) break
              debugLoopCount++
              _floor = this.subCalcFloorValues(kbNew, kt, tb)

              // examples for better understanding for input.step = 1
              // step1: 100, step2: 10, step3: 1
              if (_floor.nl >= rangeMin && _floor.nl <= rangeMax) {
                if (this.steps.kb.step1 > 0 && step === this.steps.kb.step1) { // 100
                  kbNew += (this.steps.kb.step1 + this.steps.kb.step2) // 110
                  if (kbNew > kbMax) {
                    kbNew = kbMax + this.steps.kb.step2 // + 10
                  }
                  step = this.steps.kb.step2 // 10
                } else if (this.steps.kb.step2 > 0 && step === this.steps.kb.step2) { // 10
                  kbNew += (this.steps.kb.step2 + this.steps.kb.step3) // 11
                  if (kbNew > kbMax) kbNew = kbMax + this.steps.kb.step3 // +1
                  step = this.steps.kb.step3 // 1
                } else { // 1

                  // #NEW we already found a higher kbSmaller in previous loop
                  if (kbSmaller !== null && kbNew < kbSmaller) {
                    breakLoop = true

                  // valid result
                  } else if (kbNew < kb && (kbSmaller === null || kbNew > kbSmaller)) {
                    kbSmaller = kbNew
                    breakLoop = true
                    this._cache.get_car_boundaries[cacheid].kbSmaller = kbSmaller
                  }
                }
              }
            }
          }
        }

        // kb is valid in this load range, try to fit kt
        if (kb >= this.getPrefKbMin(this.loads[i]) && kb <= this.getPrefKbMax(this.loads[i])) {
          ktMin = this.getPrefKtMin(this.loads[i])
          ktMax = this.getPrefKtMax(this.loads[i])
          if (sanitize || this.inputs.kt.step > 1) {
            ktMin = fn.sanitize(ktMin, this.inputs.kt.min, this.inputs.kt.max, this.inputs.kt.step, 'up')
            ktMax = fn.sanitize(ktMax, this.inputs.kt.min, this.inputs.kt.max, this.inputs.kt.step, 'down')
          }
          if (kt < ktMin) continue
          if (ktSmaller === null || ktSmaller < ktMax) {
            ktNew = ktMax
            step = this.steps.kt.start
            breakLoop = false
            for (; ktNew >= ktMin; ktNew -= step) {
              if (breakLoop) break
              debugLoopCount++
              _floor = this.subCalcFloorValues(kb, ktNew, tb)
              if (_floor.nl >= rangeMin && _floor.nl <= rangeMax) {
                if (this.steps.kt.step1 > 0 && step === this.steps.kt.step1) {
                  ktNew += (this.steps.kt.step1 + this.steps.kt.step2)
                  if (ktNew > ktMax) {
                    ktNew = ktMax + this.steps.kt.step2
                  }
                  step = this.steps.kt.step2
                } else if (this.steps.kt.step2 > 0 && step === this.steps.kt.step2) {
                  ktNew += (this.steps.kt.step2 + this.steps.kt.step3)
                  if (ktNew > ktMax) ktNew = ktMax + this.steps.kt.step3
                  step = this.steps.kt.step3
                } else {
                  if (ktSmaller !== null && ktNew < ktSmaller) {
                    breakLoop = true
                  } else if (ktNew < kt && (ktSmaller === null || ktNew > ktSmaller)) {
                    ktSmaller = ktNew
                    breakLoop = true
                    this._cache.get_car_boundaries[cacheid].ktSmaller = ktSmaller
                  }
                }
              }
            }
          }
        }
      }
    }

    // get bigger values from cache
    if (getBigger && this._cache.get_car_boundaries[cacheid].kbBigger !== undefined) {
      kbBigger = this._cache.get_car_boundaries[cacheid].kbBigger
      ktBigger = this._cache.get_car_boundaries[cacheid].ktBigger

    // get bigger values, loop loads from small to big
    } else if (getBigger) {
      this._cache.get_car_boundaries[cacheid].kbBigger = null
      this._cache.get_car_boundaries[cacheid].ktBigger = null
      for (j in this.loads) {
        rangeMin = this.getPrefRangeMin(this.loads[j])
        rangeMax = this.getPrefRangeMax(this.loads[j], ge)

        // kt is valid in this load range, try to fit kb
        if (kt >= this.getPrefKtMin(this.loads[j]) && kt <= this.getPrefKtMax(this.loads[j])) {
          kbMin = this.getPrefKbMin(this.loads[j])
          kbMax = this.getPrefKbMax(this.loads[j])
          if (sanitize || this.inputs.kb.step > 1) {
            kbMin = fn.sanitize(kbMin, this.inputs.kb.min, this.inputs.kb.max, this.inputs.kb.step, 'up')
            kbMax = fn.sanitize(kbMax, this.inputs.kb.min, this.inputs.kb.max, this.inputs.kb.step, 'down')
          }
          if (kb > kbMax) continue
          if (kbBigger === null || kbBigger > kbMin) {
            kbNew = kbMin
            step = this.steps.kb.start
            breakLoop = false
            for (; kbNew <= kbMax; kbNew += step) {
              if (breakLoop) break
              debugLoopCount++
              _floor = this.subCalcFloorValues(kbNew, kt, tb)
              if (_floor.nl >= rangeMin && _floor.nl <= rangeMax) {
                if (this.steps.kb.step1 > 0 && step === this.steps.kb.step1) {
                  kbNew -= (this.steps.kb.step1 + this.steps.kb.step2)
                  if (kbNew < kbMin) {
                    kbNew = kbMin - this.steps.kb.step2
                  }
                  step = this.steps.kb.step2
                } else if (this.steps.kb.step2 > 0 && step === this.steps.kb.step2) {
                  kbNew -= (this.steps.kb.step2 + this.steps.kb.step3)
                  if (kbNew < kbMin) kbNew = kbMin - this.steps.kb.step3
                  step = this.steps.kb.step3
                } else {
                  if (kbBigger !== null && kbNew > kbBigger) {
                    breakLoop = true
                  } else if (kbNew > kb && (kbBigger === null || kbNew < kbBigger)) {
                    kbBigger = kbNew
                    breakLoop = true
                    this._cache.get_car_boundaries[cacheid].kbBigger = kbBigger
                  }
                }
              }
            }
          }
        }

        // kb is valid in this load range, try to fit kt
        if (kb >= this.getPrefKbMin(this.loads[j]) && kb <= this.getPrefKbMax(this.loads[j])) {
          ktMin = this.getPrefKtMin(this.loads[j])
          ktMax = this.getPrefKtMax(this.loads[j])
          if (sanitize || this.inputs.kt.step > 1) {
            ktMin = fn.sanitize(ktMin, this.inputs.kt.min, this.inputs.kt.max, this.inputs.kt.step, 'up')
            ktMax = fn.sanitize(ktMax, this.inputs.kt.min, this.inputs.kt.max, this.inputs.kt.step, 'down')
          }
          if (kt > ktMax) continue
          if (ktBigger === null || ktBigger > ktMin) {
            ktNew = ktMin
            step = this.steps.kt.start
            breakLoop = false
            for (; ktNew <= ktMax; ktNew += step) {
              if (breakLoop) break
              debugLoopCount++
              _floor = this.subCalcFloorValues(kb, ktNew, tb)
              if (_floor.nl >= rangeMin && _floor.nl <= rangeMax) {
                if (this.steps.kt.step1 > 0 && step === this.steps.kt.step1) {
                  ktNew -= (this.steps.kt.step1 + this.steps.kt.step2)
                  if (ktNew < ktMin) {
                    ktNew = ktMin - this.steps.kt.step2
                  }
                  step = this.steps.kt.step2
                } else if (this.steps.kt.step2 > 0 && step === this.steps.kt.step2) {
                  ktNew -= (this.steps.kt.step2 + this.steps.kt.step3)
                  if (ktNew < ktMin) ktNew = ktMin - this.steps.kt.step3
                  step = this.steps.kt.step3
                } else {
                  if (ktBigger !== null && ktNew > ktBigger) {
                    breakLoop = true
                  } else if (ktNew > kt && (ktBigger === null || ktNew < ktBigger)) {
                    ktBigger = ktNew
                    breakLoop = true
                    this._cache.get_car_boundaries[cacheid].ktBigger = ktBigger
                  }
                }
              }
            }
          }
        }
      }
    }

    // only return valid values
    // 3rd and 4th value define, which one of the first values is to adjust, if the user clicks the button
    if (kbSmaller !== null) {
      _res.push([kbSmaller, kt, true, false])
    }
    if (kbBigger !== null) {
      _res.push([kbBigger, kt, true, false])
    }
    if (ktSmaller !== null) {
      _res.push([kb, ktSmaller, false, true])
    }
    if (ktBigger !== null) {
      _res.push([kb, ktBigger, false, true])
    }
    return _res
  }

  /*
  |--------------------------------------------------------------------------
  | Sub-calculation Door, Shaft, WA
  |--------------------------------------------------------------------------
  */

  /**
   * calc maximum door width from a given car width
   */
  subCalcDoorWidthMaxFromCarWidth (kb) {
    var tb = kb - this.settings.tbKbRed
    if (tb > this.settings.max) {
      tb = this.settings.max
    }
    return tb
  }

  /**
   * calc maximum door width from a given shaft width
   */
  subCalcDoorWidthMaxFromShaftWidth (sb, dl) {
    var tb

    // these formular are the same like in subCalcWa1FromDoor() and subCalcWa2FromDoor()
    // if we assume that door is same width than cabin (tb = kb)
    switch (this.settings.key) {
      case 't1':
        // assumption: e is 0, because cabin = door
        // TB = SB - WA1 - WA2
        // TB = SB - (1,5TB + 80 - TB) - 170 // the formulars from subCalcWa1FromDoor() with kb = tb are replaced
        // TB = SB - 0,5TB - 250
        // 1,5TB = SB - 250
        // TB = (SB - 250) / 1,5
        tb = Math.floor((sb - 250) / 1.5)
        break

      case 't2':
        // assumption: door is in the middle of cabin, cabin = door
        // TB = SB - WA1 - WA2
        // TB = SB - (TB + 80 - 0,5TB) - (TB + 80 - 0,5TB) // the formulars from subCalcWa1FromDoor() with kb = tb are replaced
        // TB = SB - TB - 160
        // 2TB = SB - 160
        // TB = (SB - 160) / 2
        // WA1 and WA2 can be each 90 bigger, if dl and tb >= 1250
        tb = Math.floor((sb - 160) / 2)
        if (dl === 'd2' && tb >= 1250) {
          tb = Math.floor((sb - 340) / 2)
        }
        break

      case 't3':
        // assumption: door is in the middle of cabin, cabin = door
        // TB = SB - WA1 - WA2
        // TB = SB - (0,75TB + 80 - 0,5TB) - (0,75TB + 80 - 0,5TB) // the formulars from subCalcWa1FromDoor() with kb = tb are replaced
        // TB = SB - 1,5TB - 160 + TB
        // 1,5TB = SB - 160
        // TB = (SB - 160) / 1,5
        // WA1 and WA2 can be each 90 bigger, if tb >= 2450
        tb = Math.floor((sb - 160) / 1.5)
        if (tb >= 2450) {
          tb = Math.floor((sb - 340) / 1.5)
        }
        break
    }

    // From a logical point of view, we would have to substract
    //
    // this.settings.tbKbRed
    // wa1_add_3500 and wa2_add_3500
    //
    // here to avoid a cabin-reducement that makes the door smaller.
    // But these values are very small and the above formulars calculate enought space between door and shaft, that
    // we don't have to care about that. Furthermore wa1_add_3500/wa2_add_3500 can not be calculated at this point,
    // because we don't have nl yet.

    // Check max door finally
    if (tb > this.settings.max) {
      tb = this.settings.max
    }
    return tb
  }

  /**
   * calc minimum shaft width from a given door width
   * Reverse calculation of subCalcDoorWidthMaxFromShaftWidth(),
   * all comments see there.
   */
  subCalcShaftWidthMinFromDoorWidth (tb, dl) {
    var sb
    switch (this.settings.key) {
      case 't1':
        sb = (1.5 * tb) + 250
        break
      case 't2':
        sb = (2 * tb) + 160
        if (dl === 'd2' && tb >= 1250) {
          sb += 180
        }
        break
      case 't3':
        sb = (1.5 * tb) + 160
        if (tb >= 2450) {
          sb += 180
        }
        break
    }
    sb = Math.ceil(sb)
    return sb
  }

  /**
   * Special for calc2: To calculate the max car width, it is not enought just to substract
   * wa1Min and wa2Min from shaft width. This is because the door has a defined position in the cabin
   * (E = 25 in t1, in the middle for t2 and t3) and the wall distance of the door wat_min might oberlap
   * shaft width. So here we check that and reduce the cabin, that the door fits in the shaft and has
   * its defined position in the cabin. (see calcCarFromShaftDoor.pfg)
   */
  subCalcCarWithMaxFromShaftDoor (Load, sb, tb, dl) {
    var _floor, e1, e2, nl, fl, red, wat1Min, wat2Min, wat1, wat2, kb, kbDoor1, kbDoor2, kbShaft

    // max. car width that fits in shaft
    kbShaft = this.subCalcCarWithMax(sb, Load.wa1Min, Load.wa2Min)

    // calc car width from door, separately for both sides, in dependency of E
    // (t1 has E = 25 on WA2-side, t2 and t3 are in the middle of the cabin)
    switch (this.settings.key) {
      case 't1':
        e2 = 25
        e1 = kbShaft - tb - e2
        wat2Min = 170
        wat1Min = this.subCalcShaftWidthMinFromDoorWidth(tb, dl) - tb - wat2Min
        wat1 = wat1Min - e1
        wat2 = wat2Min - e2
        if (wat1 > Load.wa1Min) {
          kbDoor1 = kbShaft - wat1 + Load.wa1Min
        } else {
          kbDoor1 = kbShaft
        }
        if (wat2 > Load.wa2Min) {
          kbDoor2 = kbShaft - wat2 + Load.wa2Min
        } else {
          kbDoor2 = kbShaft
        }
        break
      case 't2':
      case 't3':
        e1 = (kbShaft - tb) / 2
        e2 = e1
        wat1Min = (this.subCalcShaftWidthMinFromDoorWidth(tb, dl) - tb) / 2
        wat2Min = wat1Min
        wat1 = wat1Min - e1
        wat2 = wat2Min - e2
        if (wat1 > Load.wa1Min) {
          kbDoor1 = Math.floor(kbShaft - (2 * wat1) + (2 * Load.wa1Min))
        } else {
          kbDoor1 = kbShaft
        }
        if (wat2 > Load.wa2Min) {
          kbDoor2 = Math.floor(kbShaft - (2 * wat2) + (2 * Load.wa2Min))
        } else {
          kbDoor2 = kbShaft
        }
        break
    }
    kb = Math.min(kbShaft, kbDoor1, kbDoor2)

    // reduce cabin if load > 3500
    _floor = this.subCalcFloorValues(kb, Load.kt, tb)
    fl = _floor.fl
    nl = _floor.nl
    red = this.getPrefWa1Addition(Load, nl) + this.getPrefWa2Addition(Load, nl)
    if (red > 0) {
      kb -= red
    }
    return kb
  }

  /**
   * WA1 is calculated from door width by specific formular for each door
   * @param integer kb, Cabin width
   * @param integer tb, door width
   * @param integer nl, load
   */
  subCalcWa1FromDoor (Load, kb, tb, dl, nl) {
    var wa, add

    switch (this.settings.key) {
      case 't1':
        wa = Math.ceil((1.5 * tb) + 80 - kb) // would be wa, if kb = tb

        // special case for this door: formular calculates with e = 0 on wa2's side, but an e of 25 is required.
        // (e is distance between door border to cabin border, kb-tb the sum of e on both sides)
        // so we move the door 25 mm inwards.
        // Because door must be always 50 mm smaller than cabin, we always have 25 space to move the door, see: doors-pref::tbKbRed.
        if ((kb - tb) >= 25) {
          wa += 25
        }
        break

      // assumption: door is in the middle of cabin
      case 't2':
        wa = Math.ceil(tb + 80 - (kb / 2))
        if (dl === 'd2' && tb >= 1250) {
          wa += 90
        }
        break

      // assumption: door is in the middle of cabin
      case 't3':
        wa = Math.ceil((0.75 * tb) + 80 - (kb / 2))
        if (tb >= 2450) {
          wa += 90
        }
        break
    }

    // add security distance vor nl > 3500
    add = this.getPrefWa1Addition(Load, nl)
    if (add > 0) {
      wa += add
    }
    this.log('subCalcWa1FromDoor', 'kb ' + kb, 'tb ' + tb, 'dl ' + dl, 'nl ' + nl ,'add ' + add, 'wa ' + wa)
    return wa
  }

  /**
   * WA2 is calculated from door width by specific formular for each door
   */
  subCalcWa2FromDoor (Load, kb, tb, dl, nl) {
    var wa, add

    // comments in subCalcWa1FromDoor()
    switch (this.settings.key) {
      case 't1':
        wa = 170 // fix value, no formular
        if ((kb - tb) >= 25) {
          wa -= 25
        }
        break
      case 't2':
        wa = Math.ceil(tb + 80 - (kb / 2))
        if (dl === 'd2' && tb >= 1250) {
          wa += 90
        }
        break
      case 't3':
        wa = Math.ceil((0.75 * tb) + 80 - (kb / 2))
        if (tb >= 2450) {
          wa += 90
        }
        break
    }

    // add securitity distance vor nl > 3500
    add = this.getPrefWa2Addition(Load, nl)
    if (add > 0) {
      wa += add
    }
    this.log('subCalcWa2FromDoor', 'kb ' + kb, 'tb ' + tb, 'dl ' + dl,'nl ' + nl, 'add ' + add, 'wa ' + wa)
    return wa
  }

  /**
   */
  subCalcShaftWidth (kb, wa1, wa2) {
    var res = kb + wa1 + wa2
    this.log('subCalcShaftWidth', 'kb ' + kb, 'wa1 ' + wa1, 'wa2 ' + wa2, 'kb + wa1 + wa2 ' + res)
    return res
  }

  /**
   */
  subCalcWa1Min (Load, fh, ge, nl) {
    var wa1Min, add
    wa1Min = this.getPrefWa1Min(Load, fh, ge)

    // add securitity distance for nl > 3500
    add = this.getPrefWa1Addition(Load, nl)
    if (add > 0) {
      wa1Min += add
    }
    this.log('subCalcWa1Min', 'fh ' + fh, 'ge ' + ge, 'nl ' + nl, 'add ' + add, 'wa1Min ' + wa1Min)
    return wa1Min
  }

  /**
   */
  subCalcWa2Min (Load, fh, ge, nl) {
    var wa2Min, add
    wa2Min = this.getPrefWa2Min(Load, fh, ge)

    // add securitity distance for nl > 3500
    add = this.getPrefWa2Addition(Load, nl)
    if (add > 0) {
      wa2Min += add
    }
    this.log('subCalcWa2Min', 'fh ' + fh, 'ge ' + ge, 'nl ' + nl, 'add ' + add, 'wa2Min ' + wa2Min)
    return wa2Min
  }

  /**
   * calc wa3 min, depends on min cabin depth, because of min space for construction in shaft head
   */
  subCalcWa3MinFromCar (Load, kt) {
    var ktBase, wa3Min, wa3
    ktBase = this.getPrefWa3KtBase(Load, kt)
    wa3Min = this.getPrefWa3Min(Load)
    if (ktBase > kt) {
      wa3 = wa3Min + ktBase - kt
      return wa3
    } else {
      return wa3Min
    }
  }

  /**
   * This is not similar to subCalcWa3MinFromCar(), because if st would be too small
   * (stMin = ktMin + wa3Min), this would be an input error in calc2. stMin must be fix,
   * because machine in shaft head has a fix size.
   * So calc2 returns an error, if the calculation results in invalid car dimensions.
   */
  subCalcWa3MinFromShaft (Load) {
    return this.getPrefWa3Min(Load)
  }

  /**
   * reverse calculation of subCalcCarDepthMaxFromShaft
   * deep door niches are not displayed in interface so far, but in tips
   */
  subCalcShaftDepthFromCar (kt, wa, tn, dl) {
    var st, red

    // door niches reduce shaft depth
    red = this.getPrefShaftDepthReduce(tn, dl)
    if (dl === 'd2') {
      st = kt + (this.settings.tts * 2) - red
    } else {
      st = kt + wa + this.settings.tts - red
    }
    return st
  }

  /**
   * Calculation shaft head
   */
  subCalcShaftHead (Load, kh, ge, fh, skRed) {
    var sh

    sh = kh - 2100 + this.getPrefShaftHead (Load, ge)

    // shaft head reduce
    if (skRed === 'sk2') {
      sh -= this.getPrefShaftHeadRed (Load, ge, fh)
    }
    return  sh
  }

  /**
   * Calculation shaft pit
   */
  subCalcShaftPit (Load, kb, kt, ge, fh, sgRed) {
    var sg, add, add1, add2
    sg = this.getPrefShaftPit(Load, ge)

    // special case only in q1, generalize if necessary
    add1 = this.getPrefPitAddKb(Load, kb)
    add2 = this.getPrefPitAddKt(Load, kt)

    // no addition of both values, only the highest value is added
    if (add1 >= add2) {
      sg += add1
    } else if (add2 > add1) {
      sg += add2
    }
    
    // shaft pit reduce
    if (sgRed === 'sg2') {
      sg -= this.getPrefShaftPitRed (Load, ge, fh)
    }
    return (sg)
  }

  /*
  |--------------------------------------------------------------------------
  | Getter
  |--------------------------------------------------------------------------
  |
  | All methods that return values from prefernces without any calculation
  |
  */

  /**
   */
  getPrefId (Load) {
    return Load.id
  }

  /**
   */
  getPrefRangeMin (Load) {
    return Load.range.min
  }

  /**
   */
  getPrefRangeMax (Load, ge) {
    // special case: limit max in q5 and speed v2
    if (Load.id === 'q5' && ge === 'v2') {
      return Load.range.max_v2
    } else {
      return Load.range.max
    }
  }

  /**
   */
  getPrefKtMin (Load) {
    return Load.car.ktMin
  }

  /**
   */
  getPrefKtMax (Load) {
    return Load.car.ktMax
  }

  /**
   */
  getPrefKbMin (Load) {
    return Load.car.kbMin
  }

  /**
   */
  getPrefKbMax (Load) {
    return Load.car.kbMax
  }

  /**
   */
  getPrefWa1Min (Load, fh, ge) {
    return Load.wa1Min[fh][ge]
  }

  /**
   */
  getPrefWa1Max (Load, ge) {
    return Load.wa1Max[ge]
  }

  /**
   */
  getPrefWa2Min (Load, fh, ge) {
    return Load.wa2Min[fh][ge]
  }

  /**
   */
  getPrefWa2Max (Load, ge) {
    return Load.wa2Max[ge]
  }

  /**
   * special case: add security distance for load 3500
   */
  getPrefWa1Addition (Load, nl) {
    if (Load.id === 'q5' && nl >= 3500) {
      return Load.wa1Add3500
    }
    return 0
  }

  /**
   * special case: add security distance for load 3500
   */
  getPrefWa2Addition (Load, nl) {
    if (Load.id === 'q5' && nl >= 3500) {
      return Load.wa2Add3500
    }
    return 0
  }

  /**
   */
  getPrefWa3Min (Load) {
    return Load.wa3Min
  }

  /**
   */
  getPrefWa3KtBase (Load, kt) {
    if (Load.id === 'q1') {
      return Load.wa3KtBase
    }
    return kt
  }

  /**
   * tn can be
   * n1 no door niches
   * n2 normal door niches
   * n3 deep door niches
   * n4 full frontdoor
   */
  getPrefShaftDepthReduce (tn, dl) {
    if (this.settings.tnRed[tn] !== null) {
      return this.settings.tnRed[tn][dl]
    } else {
      return 0
    }
  }

  /**
   * Shaft head depending on ge
   */
  getPrefShaftHead (Load, ge) {
    return Load.skMin[ge]
  }

  /**
   * Shaft head reduction for ge = v1 and fh <= 20m
   * @TODO: fh1 must be replaced with 20m
   */
  getPrefShaftHeadRed (Load, ge, fh) {
    if ((Load.id === 'q1' || Load.id === 'q2') && ge === 'v1' && fh === 'fh1') {
      return Load.skRedFh20
    }
    return 0
  }

  /**
   * Shaft pid depending on ge
   */
  getPrefShaftPit (Load, ge) {
    return Load.sgMin[ge]
  }

  /**
   * special case: shaft pit addition in q1 and shaft width > 1600
   */
  getPrefPitAddKb (Load, kb) {
    if (Load.id === 'q1' && kb > 1600) {
      return Load.sgAddKb1600
    }
    return 0
  }

  /**
   * special case: shaft pit addition in q1 and shaft depth > 2100
   */
  getPrefPitAddKt (Load, kt) {
    if (Load.id === 'q1' && kt > 2100) {
      return Load.sgAddKt2100
    }
    return 0
  }

  /**
   * Shaft pid reduction for ge = v1 and fh <= 30m
   * @TODO: fh1 must be replaced with 30m
   */
  getPrefShaftPitRed (Load, ge, fh) {
    if ((Load.id === 'q1' || Load.id === 'q2') && ge === 'v1' && fh === 'fh1') {
      return Load.sgRedFh30
    }
    return 0
  }

  /**
   * Loops steps for cabin optimization, depending on the input step value
   */
  getLoopSteps (step) {
    var max, res

    // maximum step is a multiple of step and <= 100
    if (step <= 100) {
      max = 100 - (100 % step)
    } else {
      max = step
    }

    // step3 is the last step in optimization and always = step
    res = {
      step1: 0, // 0-value will be skipped in loops
      step2: 0,
      step3: step,
      start: step
    }

    // check, if step3 * 10 fits in max to start optimazation with step2
    if (res.step3 * 10 <= max) {
      res.step2 = res.step3 * 10
      res.start = res.step2

      // dito for step3, which will only be true for step = 1
      if (res.step2 * 10 <= max) {
        res.step3 = res.step2 * 10
        res.start = res.step3
      }
    }
    return res
  }

  /**
   */
  getLoadById (id) {
    var i
    for (i in this.loads) {
      if (this.loads[i].id === id) {
        return this.loads[i]
      }
    }
    return null
  }

  /*
  |--------------------------------------------------------------------------
  | Validation, Tipps
  |--------------------------------------------------------------------------
  */

  validateUserInput () {
    var tb, tbMax, sbMin
    // strno | param1 to parse or null | param2 to parse or null | object with adjust-values or false if none

    // Shaftpit and -head reduce is only available at ge = v1 and fh = 40 (in fact fh = 20 and 30, but the
    // interface has no distinction)
    if (this.result.ge !== 'v1' || this.result.fh !== 'fh1') {
      if (this.result.skRed === 'sk2') {
        this.setError(26)
        return ['str26', null, null, { skRed: 'sk1' }]
      } else if (this.result.sgRed === 'sg2') {
        this.setError(27)
        return ['str27', null, null, { sgRed: 'sg1' }]
      }
    }

    // tb min is easy, simply compare to this.settings.min
    if (this.result.tb < this.settings.min) {
      this.setError(11)
      return ['str11', this.result.tb, this.settings.min, { tb: this.settings.min }]
    }

    // tb max can be limited by this.settings.max or car/shaft
    if (this.calc === 'cabin') {
      tbMax = this.subCalcDoorWidthMaxFromCarWidth(this.result.kb)
      if (tbMax > this.settings.max) {
        tbMax = this.settings.max
      }
      if (this.result.tb > tbMax) {
        tbMax = fn.sanitize(tbMax, this.settings.min, this.settings.max, this.settings.step, 'down')
        this.setError(12)
        return ['str12', this.result.tb, tbMax, { tb: tbMax }]
      }
    }

    // tb must be a multiple of step
    // this error may occur because step can be different for each door
    if (Math.floor(this.result.tb / this.settings.step) !== this.result.tb / this.settings.step) {
      tb = fn.sanitize(this.result.tb, this.settings.min, this.settings.max, this.settings.step, 'down')
      this.setError(21) // was 12, zahlendreher?
      return ['str21', this.settings.step, tb, { tb: tb }]
    }

    // settings for door niches are not valid
    if (this.settings.tnRed[this.result.tn] === null) {
      this.setError(24)
      return ['str24', null, null, { tn: 'n2' }]
    }

    if (this.calc === 'shaft') {

      // max door with for the given shaft
      tbMax = this.subCalcDoorWidthMaxFromShaftWidth(this.result.sb, this.result.dl)
      if (tbMax > this.settings.max) {
        tbMax = this.settings.max
      }
      if (this.result.tb > tbMax) {

        // if door is too big and we can correct the values by reducing the door,
        // we choose this options, because calculation is a shaft-calculation
        if (tbMax > this.settings.min) {

          // step needed, because it is set only to current door
          tbMax = fn.sanitize(tbMax, this.settings.min, this.settings.max, this.settings.step, 'down')
          this.setError(12)
          return ['str12', this.result.tb, tbMax, { tb: tbMax }]

        // otherwise we make the shaft wider
        } else {
          sbMin = this.subCalcShaftWidthMinFromDoorWidth(this.result.tb, this.result.dl)
          if (this.result.sb < sbMin) {
            sbMin = fn.sanitize(sbMin, this.inputs.sb.min, this.inputs.sb.max, this.inputs.sb.step, 'up')
            this.setError(17)
            return ['str17', this.result.sb, sbMin, { sb: sbMin }]
          }
        }
      }
    }
  }

  /**
   * Validate speed input
   * can only be done AFTER calculation, because it depends on load-range
   * 
   * @param {string} ge, the user selected speed v1 - v5
   * @param {object} geValid , the valid speeds from load config
   */
  validateInputGe (ge, geValid) {
    var geMax, i, j, param1, param2
    if(!geValid[ge]) {

      // get highest valid ge
      for (i in geValid) {
        if (geValid[i]) {
          geMax = i
        }
      }

      // get translations for ge and geMax
      for (j = 0; j < this.inputs.ge.options.length; j++) {
        if (this.inputs.ge.options[j].value === ge) {
          param1 = this.inputs.ge.options[j].label
        }
        if (this.inputs.ge.options[j].value === geMax) {
          param2 = this.inputs.ge.options[j].label
        }
      }
      this.setError(25)
      return ['str25', param1, param2, { ge: geMax }]
    }
    return false
  }

  /**
   * Validate, if shaft head reduce is valid for this load range
   * 
   * @param {string} nb, q1-q5
   * @param {string} skRed, sk1 | sk2
   */
  validateInputSkRed (nb, skRed) {
    if (skRed === 'sk2' && nb !== 'q1' && nb !== 'q2') {
      this.setError(28)
      return ['str28', null, null, { skRed: 'sk1' }]
    }
    return false
  }

  /**
   * Validate, if shaft pit reduce is valid for this load range
   * 
   * @param {string} nb, q1-q5
   * @param {string} sgRed, sg1 | sg2
   */
  validateInputSgRed (nb, sgRed) {
    if (sgRed === 'sg2' && nb !== 'q1' && nb !== 'q2') {
      this.setError(29)
      return ['str29', null, null, { sgRed: 'sg1' }]
    }
    return false
  }

  /**
   * give the user optimization hints
   */
  getTipps () {
    var Row, Btn, ktMax, kbMax, sbMin, stMin, alt, cssclass, Res, _msg, i

    // Format of _msg:
    // strno | param1 | param2 | object with adjust-values or false if none
    _msg = []

    // specific tips
    if (this.calc === 'cabin') {

      // optimize car width by reverse calculation
      // #TODO: 12000 loops
      if (this.settings.optimizationTipps) {
        Res = this.subCalcCarFromShaft(
          this.result.sb,
          this.result.st,
          this.result.tb,
          this.result.kh,
          this.result.dl,
          this.result.ge,
          this.result.fh,
          this.result.tn,
          this.result.skRed,
          this.result.sgRed,
          0,
          this.result.kt,
          false
        )
        if (Res.error === 0) {
          kbMax = fn.sanitize(Res.data.kb, this.inputs.kb.min, this.inputs.kb.max, this.inputs.kb.step, 'down')
          if (kbMax > this.result.kb && Res.data.nb === this.result.nb) {
            _msg.push(['str71', kbMax, false, {'kb': kbMax}, true])
          }
        }
      }

      // optimize car depth with current door niche
      // #TODO: 12000 loops
      if (this.settings.optimizationTipps) {
        Res = this.subCalcCarFromShaft(
          this.result.sb,
          this.result.st,
          this.result.tb,
          this.result.kh,
          this.result.dl,
          this.result.ge,
          this.result.fh,
          this.result.tn,
          this.result.skRed,
          this.result.sgRed,
          this.result.kb,
          0,
          false
        )
        if (Res.error === 0) {
          ktMax = fn.sanitize(Res.data.kt, this.inputs.kt.min, this.inputs.kt.max, this.inputs.kt.step, 'down')
          if (ktMax > this.result.kt && Res.data.nb === this.result.nb) {
            _msg.push(['str77', ktMax, false, {'kt': ktMax, 'tn': 'n2'}, true])
          }
        }
      }

      // optimize car depth with normal door niche
      // #TODO: 12000 loops
      if (this.settings.optimizationTipps) {
        if (this.result.tn === "n1") {
          Res = this.subCalcCarFromShaft(
            this.result.sb,
            this.result.st,
            this.result.tb,
            this.result.kh,
            this.result.dl,
            this.result.ge,
            this.result.fh,
            'n2',
            this.result.skRed,
            this.result.sgRed,
            this.result.kb,
            0,
            false
          )
          if (Res.error === 0) {
            ktMax = fn.sanitize(Res.data.kt, this.inputs.kt.min, this.inputs.kt.max, this.inputs.kt.step, 'down')
            if (ktMax > this.result.kt && Res.data.nb === this.result.nb) {
              _msg.push(['str72', ktMax, false, {'kt': ktMax, 'tn': 'n2'}, true])
            }
          }
        }
      }

      // optional messages
      if (this.result.tn !== "n1" && this.settings.msgTn !== undefined) {
        _msg.push([this.settings.msgTn, false, false, false, true])
      }
      if (this.result.skRed === 'sk2') {
        _msg.push(['str80', false, false, false, true])
      }
      if (this.result.sgRed === 'sg2') {
        _msg.push(['str81', false, false, false, true])
      }
    }
    if (this.calc === 'shaft') {

      // optimize shaft width and depth by reverse calculation
      if (this.settings.optimizationTipps) {
        Res = this.subCalcShaftFromCar(
          this.result.kb,
          this.result.kt,
          this.result.kh,
          this.result.tb,
          this.result.dl,
          this.result.ge,
          this.result.fh,
          this.result.tn,
          this.result.skRed,
          this.result.sgRed
        )
        if (Res.error === 0) {
          sbMin = fn.sanitize(Res.data.sb, this.inputs.sb.min, this.inputs.sb.max, this.inputs.sb.step, 'up')
          if (sbMin < this.result.sb) {
            _msg.push(['str75', sbMin, false, {'sb': sbMin}, true])
          }
          stMin = fn.sanitize(Res.data.st, this.inputs.st.min, this.inputs.st.max, this.inputs.st.step, 'up')
          if (stMin < this.result.st) {
            _msg.push(['str76', stMin, false, {'st': stMin}, true])
          }
        }
      }
    }

    // general messages
    _msg.push([this.settings.msgE, false, false, false, false])
    _msg.push(['str70', false, false, false, false])
    // _msg.push(['page.disclaimer', false, false, false, false])
    return _msg
  }

  log () {
    if (!this.debug) {
      return
    }
    var args = Array.from(arguments)
    if (args.length && args.length > 1) {
      console.groupCollapsed(args.shift())
      if (args.length > 0) {
        args.forEach((item, index) => {
          console.log(item)
        })
      }
      console.groupEnd()
    } else {
      console.log(args[0])
    }
  }
}

export default new CalculationService()
