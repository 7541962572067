import fn from '../services/GlobalService'
import _forOwn from 'lodash/forOwn'
import _merge from 'lodash/merge'
import _clone from 'lodash/clone'
import { languages } from '../config/global.json'
import i18n from '../services/LanguageService'
import CalculationHelperService from '../services/CalculationHelperService'
import Bus from '../services/EventService'
import InputModel from '../models/InputModel'
import InputModelCollection from '../models/InputModelCollection'

export default {
  modules: {},
  state: {
    locale: null,
    door: null,
    calc: null,
    product: null,
    locked: null,
    inputs: {

      // cabin width
      kb: {
        value: null,
        label: null,
        unit: null,
        min: null,
        max: null,
        step: null,
        sliderStep: null,
        rangeMin: null,
        rangeMax: null,
        lockable: false,
        disabled: false
      },

      // cabin depth
      kt: {
        value: null,
        label: null,
        unit: null,
        min: null,
        max: null,
        step: null,
        sliderStep: null,
        rangeMin: null,
        rangeMax: null,
        lockable: false,
        disabled: false
      },

      // shaft width
      sb: {
        value: null,
        label: null,
        unit: null,
        min: null,
        max: null,
        step: null,
        sliderStep: null,
        rangeMin: null,
        rangeMax: null,
        lockable: false,
        disabled: false
      },

      // shaft depth
      st: {
        value: null,
        label: null,
        unit: null,
        min: null,
        max: null,
        step: null,
        sliderStep: null,
        rangeMin: null,
        rangeMax: null,
        lockable: false,
        disabled: false
      },

      // door width
      tb: {
        value: null,
        label: null,
        unit: null,
        min: null,
        max: null,
        step: null,
        sliderStep: null,
        rangeMin: null,
        rangeMax: null,
        lockable: false,
        disabled: false
      },

      // cabin height
      kh: {
        value: null,
        label: null,
        unit: null,
        min: null,
        max: null,
        step: null,
        sliderStep: null,
        rangeMin: null,
        rangeMax: null,
        lockable: false,
        disabled: false
      },

      // lifting height
      fh: {
        value: null,
        label: null,
        options: []
      },

      // speed
      ge: {
        value: null,
        label: null,
        options: []
      },

      // two doors
      dl: {
        value: null,
        label: null,
        options: []
      },

      // door niches
      tn: {
        value: null,
        label: null,
        options: []
      },

      // shaft head reduce
      skRed: {
        value: null,
        label: null,
        options: []
      },

      // shaft pit reduce
      sgRed: {
        value: null,
        label: null,
        options: []
      },

      // presets for cabin calculation
      se_cabin: {
        value: null,
        label: null,
        unit: null,
        options: []
      },

      // presets for shaft calculation
      se_shaft: {
        value: null,
        label: null,
        unit: null,
        options: []
      }
    },
    print: ['kb', 'kt', 'tb', 'kh', 'nl', 'pe', 'sb', 'st', 'wa1', 'wa2', 'wa3', 'sg', 'sk', 'dl', 'fh', 'tn', 'ge']
  },

  /**
   * get values
   */
  getters: {

    /**
     * Main method to get any property of an input field
     *
     * @param {string} key, the name of the input field
     * @param {string} prop, the name of the property
     * @return {mixed}
     */
    get: (state) => (key, prop) => {
      return state.inputs[key][prop]
    },
    getInputError: (state) => (key) => {
      return state[state.door].errorInputs[key]
    },
    isCabin: (state) => {
      return state.calc === 'cabin'
    },
    isShaft: (state) => {
      return state.calc === 'shaft'
    },
    currentDoor: (state) => {
      return state[state.door]
    },
    getDoor: (state) => (key) => {
      return state[key]
    },
    currentErrorMsg: (state) => {
      if (state.door) {
        return state[state.door].errmsg
      } else {
        return []
      }
    },
    isDoor: (state) => (key) => {
      return state.door === key
    },
    hasError: (state) => (key) => {
      return state[key].errno > 0
    },
    getError: (state) => (key) => {
      return state[key].errno
    },
    hasSeInput: (state) => {
      if (state.calc === 'cabin' && state.inputs.se_cabin.options) {
        return true
      }
      if (state.calc === 'shaft' && state.inputs.se_shaft.options) {
        return true
      }
      return false
    },
    currentDoorError: (state) => {
      return state[state.door].errno
    },
    currentErrorPanel: (state) => {
      return state[state.door].errorPanel
    },
    tippsCount: (state) => {
      return state[state.door].tipps.filter(tipp => tipp[4]).length
    },
    printParams: (state) => {
      var globals, inputs
      globals = {
        locale: state.locale,
        calc: state.calc,
        door: state.door
      }
      inputs = {
        se_cabin: state.inputs.se_cabin,
        se_shaft: state.inputs.se_shaft
      }
      return CalculationHelperService.getPrintParams(globals, state[state.door].result, inputs, state[state.door].tipps, state.print)
    }
  },

  /**
   * change values
   */
  mutations: {

    /**
     * Init the calculation:
     * read inputs configs
     * select default door
     * start first calculation with default inputs
     * @param {object} state
     * @param {object} payload { calc }
     */
    init (state, payload) {
      var selectDoorAction, doorConfig
      state.calc = payload.calc
      state.product = payload.config.product
      _forOwn(state.inputs, (values, name) => {
        _forOwn(values, (value, param) => {
          if (fn.has(payload.config.inputs, [name, param])) {
            state.inputs[name][param] = payload.config.inputs[name][param]
          } else {
            state.inputs[name][param] = null
          }
        })
      })

      // set rangeMin, rangeMax
      // (so far min/max are also the range-values)
      _forOwn(state.inputs, (values, name) => {
        _forOwn(values, (value, param) => {
          if (param === 'min' && payload.config.inputs[name][param]) {
            state.inputs[name].rangeMin = payload.config.inputs[name].min
          }
          if (param === 'max' && payload.config.inputs[name][param]) {
            state.inputs[name].rangeMax = payload.config.inputs[name].max
          }
        })
      })

      // Init door's states
      // set rangeMin, rangeMax for tb
      // get lowest/highes values from settings
      _forOwn(payload.config.doors, (values, key) => {
        if (!state.inputs.tb.rangeMin || values.min < state.inputs.tb.rangeMin) {
          state.inputs.tb.rangeMin = values.min
        }
        if (!state.inputs.tb.rangeMax || values.max > state.inputs.tb.rangeMax) {
          state.inputs.tb.rangeMax = values.max
        }
        if (!selectDoorAction) {
          selectDoorAction = key + '/selectDoor'
        }

        // init door
        values.key = key
        this.commit(key + '/init', values)
      })

      this.dispatch(selectDoorAction)
      // fn.expose(state.inputs)
      this.commit('calculate', { calc: 'cabin', slider: false })
    },

    /**
     * handler to update store an calculate after the user changed an input element manually
     *
     * @param {object} state
     * @param {InputModel} Input
     */
    inputValueChanged (state, Input) {
      var Update, Collection
      state.inputs[Input.key].value = Input.value

      // Workaround
      // If other inputs are added which mustn't start a calculation, there should be
      // added a property to config/inputs.json. So far we only have se-input, so this is
      // the most economical way to handle it.
      if (Input.is('se_cabin') || Input.is('se_shaft')) {
        return
      }

      // Calculation
      Update = CalculationHelperService.getDependentValue(state.calc, state.inputs, Input)
      if (Update) {
        state.inputs[Update.key].value = Update.value
        Collection = new InputModelCollection()
        Collection.add(Update)
        Bus.fire('calc/on-input-value-changed', Collection)
      }
      this.commit('calculate', { slider: Input.slider, shaft: null })
      if (!Input.slider) {
        this.commit('updatePresetsInput')
        Bus.fire('calc/on-after-input-value-changed')
      }
    },

    /**
     * main procecure to start calculation for all doors
     * @param {} state
     * @param {*} payload { calc, slider }
     */
    calculate (state, payload) {
      var Update
      this.dispatch('calculateDoor', payload)
      if (!payload.slider) {
        Update = CalculationHelperService.getLockedDependent(state, 'auto')
        Bus.fire('calc/on-after-calculation', Update)
      }
    },

    /**
     * handler to update store, input elements and calculate
     * used by adjustments, tipps or any method to start a calculation by setting multiple values
     *
     * @param {object} state
     * @param {InputModelCollection} Collection
     */
    setInputValues (state, Collection) {
      for (var key in Collection.InputModels) {
        var Input = Collection.get(key)
        state.inputs[key].value = Input.value
        var Update = CalculationHelperService.getDependentValue(state.calc, state.inputs, Input)
        if (Update) {
          state.inputs[Update.key].value = Update.value
          Collection.add(Update)
        }
      }
      Bus.fire('calc/on-input-value-changed', Collection)
      this.commit('calculate', { slider: false, shaft: null })
      this.commit('updatePresetsInput')
      Bus.fire('calc/on-after-input-value-changed')
    },

    /**
     * handler to select one of multiple results
     * used by multi panel
     * @param {object} state
     * @param {object} shaft
     */
    selectResultValues (state, shaft) {
      this.commit('calculate', { slider: false, shaft: shaft })
    },

    /**
     * Inform dependent Input about the lock of an other Input, calculate dependent Input min/max
     * Input an be false to get Input from state.locked
     *
     * @param {object} state
     * @param {InputModel} Input
     */
    setLockDependent (state, Input) {
      var Update
      if (Input.is('st') || Input.is('kt')) {
        return
      }
      if (Input.isLocked()) {
        state.locked = Input.key
      } else {
        state.locked = null
      }
      Update = CalculationHelperService.getLockedDependent(state, Input)
      Bus.fire('calc/on-set-lock-dependent', Update)
    },

    /**
     * sub procedure to update presets input se to show the corresponding set depending
     * on the values kb and kt
     *
     * @param {object} state
     * @param {InputModelCollection} Collection
     */
    updatePresetsInput (state) {
      var Input, Collection
      if (this.getters.hasSeInput) {
        if (this.getters.isCabin) {
          Input = CalculationHelperService.getSeFromKbKt(state.inputs.kb.value, state.inputs.kt.value, state.inputs.se_cabin.options)
          if (!Input.compare(state.inputs.se_cabin.value)) {
            state.inputs.se_cabin.value = Input.value
            Collection = new InputModelCollection()
            Collection.add(Input)
            Bus.fire('calc/on-input-value-changed', Collection)
          }
        }
        if (this.getters.isShaft) {
          Input = CalculationHelperService.getSeFromKbKt(state.inputs.kb.value, state.inputs.kt.value, state.inputs.se_shaft.options)
          if (!Input.compare(state.inputs.se_shaft.value)) {
            state.inputs.se_shaft.value = Input.value
            Collection = new InputModelCollection()
            Collection.add(Input)
            Bus.fire('calc/on-input-value-changed', Collection)
          }
        }
      }
    },

    setCurrentDoor (state, key) {
      state.door = key
    },

    setTbInputSettings (state, settings) {
      state.inputs.tb.min = settings.min
      state.inputs.tb.max = settings.max
      state.inputs.tb.step = settings.step
      state.inputs.tb.sliderStep = settings.sliderStep
    },

    /**
     * enable/disable input options for select and radio fields
     * @param {*} state 
     * @param {*} avail, like { name: inputName, options: { value1: true, value2: false }}
     */
    setInputOptions (state, { name, options }) {
      var key, value
      for (key in state.inputs[name].options) {
        value = state.inputs[name].options[key].value
        state.inputs[name].options[key].disabled = !options[value]
      }
    },

    setLanguage (state, locale) {
      i18n.locale = locale
      state.locale = locale
      Bus.fire('global/set-language', locale)
    }
  },

  /**
   * Asynchronous, return Promises
   */
  actions: {
    /**
     * Switch language
     */
    loadLanguage ({ commit }, lang) {
      return new Promise((resolve, reject) => {
        if (!fn.inArray(lang, languages.available)) {
          resolve()
          return
        }
        if (lang in i18n.messages) {
          commit('setLanguage', lang)
          resolve()
          return
        }
        import(/* webpackChunkName: "lang-[lang]" */ '@/lang/' + lang + '.json')
          .then((res) => {
            i18n.setLocaleMessage(lang, res)
            commit('setLanguage', lang)
            resolve()
          })
      })
    }
  }
}
