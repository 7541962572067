import fn from '../services/GlobalService'
import _forOwn from 'lodash/forOwn'
import CalculationService from '../services/CalculationService'
import CalculationHelperService from '../services/CalculationHelperService'
import Bus from '../services/EventService'

export default {
  namespaced: true,
  state () {
    return {
      calculating: false,
      errno: 0,
      errorPanel: 0,
      errorInputs: {
        kb: false,
        kt: false,
        sb: false,
        st: false,
        tb: false,
        kh: false,
        ge: false,
        tn: false
      },

      // config values from /config/doors
      settings: {
        key: null,
        id: null,
        fl: null,
        roundLoad: null,
        tbKbRed: null,
        tnRed: {},
        tts: null,
        min: null,
        max: null,
        step: null,
        sliderStep: null,
        msgE: null,
        msgTn: null
      },

      // same defintion in CalculationService.js
      result: {
        kb: 0,
        kt: 0,
        sb: 0,
        st: 0,
        tb: 0,
        kh: 0,
        tn: null,
        dl: null,
        ge: null,
        fh: null,
        skRed: null,
        sgRed: null,
        nb: null,
        nl: 0,
        pe: 0,
        fl1: 0,
        fl2: 0,
        fl: 0,
        wa1Min: 0,
        wa1Door: 0,
        wa1: 0,
        wa2Min: 0,
        wa2Door: 0,
        wa2: 0,
        wa3: 0,
        sg: 0,
        sk: 0
      },
      adjustments: [],
      multi: [],
      tipps: [],
      errmsg: []
    }
  },
  getters: {},

  /**
   * change values
   */
  mutations: {

    /**
     * Init - getting settings
     * @param {*} state
     * @param {*} setting
     */
    init (state, settings) {
      _forOwn(settings, (value, key) => {
        state.settings[key] = value
      })
    }
  },

  /**
   * Asynchronous, return Promises
   */
  actions: {

    /**
     */
    selectDoor ({ state, commit, dispatch, rootState }) {
      var Update
      return new Promise((resolve) => {
        commit('setCurrentDoor', state.settings.key, { root: true })
        commit(
          'setTbInputSettings',
          {
            'min': state.settings.min,
            'max': state.settings.max,
            'step': state.settings.step,
            'sliderStep': state.settings.sliderStep
          },
          { root: true }
        )
        commit(
          'setInputOptions',
          {
            name: 'tn',
            options: CalculationHelperService.getAvailableTn(state.settings.tnRed)
          },
          { root: true }
        )
        dispatch('updateInputs')
        Update = CalculationHelperService.getLockedDependent(rootState, 'auto')
        Bus.fire('calc/on-after-door-changed', Update)
        resolve()
      })
    },

    /**
     * Update Inputs after a door was selected and after calculation
     */
    updateInputs ({ state, commit }) {
      return new Promise((resolve) => {
        commit(
          'setInputOptions',
          {
            name: 'ge',
            options: CalculationHelperService.getAvailableGe(state.settings.nb)
          },
          { root: true }
        )
        resolve()
      })
    },

    /**
     * Where the magic happens
     */
    calculateDoor: {
      root: true,
      handler ({ state, rootState }, payload) {

        // never true, just for saftey
        if (state.calculating) {
          return
        }
        state.calculating = true
        var Return = CalculationService.do(
          rootState.calc,
          rootState.inputs,
          state.settings,
          payload.slider,
          payload.shaft
        )
        if (!payload.slider) {
          state.adjustments = Return.adjustments
          state.multi = Return.multi
          state.tipps = Return.tipps
          state.errmsg = Return.errmsg
        }

        /**
         * Error handling
         * only use result if no error occured (so freeze old calculation in case of error)
         *
         * state.errno is the errno in every moment
         *
         * | Error | show error   | adjust value | show Panel   |
         * |-------|--------------|--------------|--------------|
         * | 1     |              |              | 8 fatal      |
         * | 11    | tb           | tb           | 5 error      |
         * | 12    | tb           | tb           | 5 error      |
         * | 16    | sb, st       | sb, st       | 7 multi      |
         * | 17    | sb           | sb           | 5 error      |
         * | 18    | sb, st       | sb, st       | 6 adjustment |
         * | 19    | kb, kt       | kb, kt       | 6 adjustment |
         * | 20    | tb           | tb           | 5 error      |
         * | 21    | tb           | tb           | 5 error      |
         * | 22    | tb           | tb           | 5 error      |
         * | 23    | kb, kt       | ge           | 5 error      |
         * | 24    | tn           | tn           | 5 error      |
         * | 25    | v            | v            | 5 error      |
         * | 26,28 | skRed        | skRed        | 5 error      |
         * | 27,29 | sgRed        | sgRed        | 5 error      |
         */

        // reset errors
        state.errno = Return.errno
        var key
        for (key in state.errorInputs) {
          state.errorInputs[key] = false
        }
        if (state.errno > 0) {
          var panel
          switch (state.errno) {
            case 1:
              state.errorInputs.kb = true
              state.errorInputs.kt = true
              state.errorInputs.sb = true
              state.errorInputs.st = true
              state.errorInputs.tb = true
              state.errorInputs.kh = true
              panel = 8
              break
            case 11:
            case 12:
            case 20:
            case 21:
            case 22:
              state.errorInputs.tb = true
              panel = 5
              break
            case 17:
              state.errorInputs.sb = true
              panel = 5
              break
            case 16:
              state.errorInputs.sb = true
              state.errorInputs.st = true
              panel = 7
              break
            case 18:
              state.errorInputs.sb = true
              state.errorInputs.st = true
              panel = 6
              break
            case 19:
              state.errorInputs.kb = true
              state.errorInputs.kt = true
              panel = 6
              break
            case 23:
              state.errorInputs.kb = true
              state.errorInputs.kt = true
              panel = 5
              break
            case 24:
              state.errorInputs.tn = true
              panel = 5
              break
            case 25:
              state.errorInputs.ge = true
              panel = 5
              break
            case 26:
            case 28:
              state.errorInputs.skRed = true
              panel = 5
              break
            case 27:
            case 29:
              state.errorInputs.sgRed = true
              panel = 5
              break
          }
          if (!payload.slider) {
            state.errorPanel = panel
          }
        } else {

          // store result
          _forOwn(Return.result, (value, key) => {
            state.result[key] = value
          })
          state.errorPanel = 0
        }
        state.calculating = false
      }
    }
  }
}
